export default {
    TITULO_LISTA: "Links de pagamentos",
    TITULO_FORMULARIO_NOVO: "Novo link",
    TITULO_FORMULARIO_EDICAO: "Editar link",
    NOVO: "Novo link",
    VISUALIZAR: "Visualizar",
    INATIVAR: "Inativar",
    FILTRO: "Pesquise pelo email ou celular",
    TOOLTIP: "Selecione o estabelecimento que gostaria de víncular esse contato",
    REGERAR_LINK: "Regerar link",
    TABELA: {
        VALOR: "Valor",
        TIPO: "Tipo",
        EMAIL: "Email",
        CELULAR: "Celular",
        DATA_COBRANCA: "Data de cobrança",
        DATA_EXPIRACAO: "Data de expiração",
        CRIADO: "Criado por",
        CODIGO: "Código",
    },
    LABEL: {
        FILTRO: "Filtro",
        DINICIAL: "Data inicial",
        DFINAL: "Data final"
    },
    TIPO_COBRANCA: "Selecione o tipo de cobrança",
    NOME_PRODUTO: 'Nome do produto',
    QUANTIDADE: 'Quantidade',
    VALOR: 'Valor',
    ADICIONAR_SACOLA: 'Adicionar',
    COD_PRODUTO: 'Código',
    DESCONTO: "Desconto",
    PARCELAS: "Parcelas",
    PLANO: "Plano",
    ACOES: "Ações",
    DADOS_ENVIO: "Dados do envio",
    EMAIL: "E-mail",
    VALOR_AVULSO: "Valor",
    DESCRICAO: "Descrição",
    ABA_AVISTA: "À vista / Parcelado",
    ABA_RECORRENCIA: "Recorrência",
    NOVO_PLANO: "Novo plano",
    NOVO_CLIENTE: "Novo cliente",
    ESTABELECIMENTO: "Estabelecimento",
    CLIENTE: "Cliente",
    CANCELAR: "Cancelar",
    ENVIAR: "Enviar",
    MENSAGEM_SUCESSO: "Link salvo e enviado com sucesso!",
    VALOR_TOTAL: "Valor total",
    TITULO_SALVAR: "Deseja enviar o link de pagamento?",
    TITULO_REGERAR: "Deseja regerar o link de pagamento?",
    REGERADO_SUCESSO: "Link de pagamento regerado com sucesso!",
    ACESSAR_LINK: "Acessar link",
    COPIAR_LINK: "Copiar link",
    CONSULTA_STATUS_ESITEF: "Consultar status (ESitef)",
    TITULO_CONSULTA_STATUS_ESITEF: "Deseja forçar a consulta de status no ESitef?",
    CONSULTA_AGENDAMENTO: "Obter Agendamento (ESitef)",
    TITULO_CONSULTA_AGENDAMENTO: "Deseja buscar o agendamento no ESitef?",
}